





















































import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import FlexibleCarousel1 from '@/calendesk/sections/section/shared/components/FlexibleCarousel1.vue'
import FlexibleGallery from '@/calendesk/sections/section/shared/mixins/FlexibleGallery'

export default mixins(FlexibleGallery).extend({
  name: 'FlexibleGallery1',
  components: { DefaultButton, FlexibleCarousel1 }
})
