








































































import mixins from 'vue-typed-mixins'
import PreviewAsset from '@/calendesk/models/PreviewAsset'
import { mapActions } from 'vuex'
import FlexibleGallery from '@/calendesk/sections/section/shared/mixins/FlexibleGallery'
export default mixins(FlexibleGallery).extend({
  name: 'FlexibleCarousel1',
  data () {
    return {
      carousel: 0,
      carouselItems: new Array<Array<string>>()
    }
  },
  watch: {
    items () {
      this.generatePortfolioImages()
    },
    data () {
      this.generatePortfolioImages()
    },
    isMobile () {
      this.generatePortfolioImages()
    }
  },
  computed: {
    getImageWidth (): string {
      const value = ((100 - this.data.configuration.wb_gallery_distance__number__) / this.numberOfPicturesOnScreen)

      return `${value}%`
    },
    getGalleryWidth (): string {
      if (this.isMobile) {
        return '100%'
      }

      return this.data.configuration.wb_gallery_width__number__
        ? this.data.configuration.wb_gallery_width__number__ + '%'
        : '100%'
    },
    getImageStyle (): Record<string, string> {
      return {
        margin: `0 ${this.data.configuration.wb_gallery_distance__number__}px`
      }
    },
    numberOfPicturesOnScreen (): number {
      return this.isMobile
        ? this.data.configuration.wb_gallery_mobile_image_number__number__
        : this.data.configuration.wb_gallery_image_number__number__
    }
  },
  mounted () {
    this.generatePortfolioImages()
  },
  methods: {
    ...mapActions({
      openPreviewAsset: 'preview/openPreviewAsset'
    }),
    openPreviewAssetHandle (url: string) {
      if (this.data.configuration.wb_image_zoom_on_click__checkbox__) {
        this.openPreviewAsset(new PreviewAsset(true, url))
      }
    },
    prev (): void {
      if (this.carousel === 0) {
        this.carousel = this.data.images.length - 1
        return
      }
      this.carousel--
    },
    next (): void {
      if (this.carousel === this.data.images.length - 1) {
        this.carousel = 0
        return
      }
      this.carousel++
    },
    goTo (index: number): void {
      this.carousel = index
    },
    generatePortfolioImages (): void {
      const n = this.numberOfPicturesOnScreen
      const items = new Array<string>()

      const images = [...this.data.images]

      images.sort((img1, img2) => {
        const slug1: number = parseInt(img1.slug.replace(/^\D+/g, ''))
        const slug2: number = parseInt(img2.slug.replace(/^\D+/g, ''))
        return slug1 - slug2
      })

      images.forEach((img: any) => {
        items.push(`${this.appConfiguration.imageBaseUrl}${img.name}`)
      })

      this.carouselItems = new Array(Math.ceil(items.length / n))
        .fill('')
        .map(() => items.splice(0, n))
    }
  }
})
