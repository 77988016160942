import mixins from 'vue-typed-mixins'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'
import Section from '@/calendesk/sections/section/mixins/Section'

export default mixins(Section).extend({
  computed: {
    galleryStyle1 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_1
      }

      return false
    },
    galleryStyle2 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_2
      }

      return false
    },
    galleryStyle3 (): boolean {
      if (this.data.configuration.wb_gallery_style__select__) {
        return this.data.configuration.wb_gallery_style__select__.value === GalleryStyleType.STYLE_3
      }

      return false
    }
  }
})
