var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{staticClass:"full-height",attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[_c('v-row',{staticClass:"full-height",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"overflow-hidden",style:({ minHeight: ((_vm.data.configuration.wb_height__style_height__) + "px") })},[(!_vm.imageHidden && _vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('flexible-carousel1',{class:( _obj = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj[_vm.classForImagePosition] = true, _obj ),attrs:{"data":_vm.data}}):_vm._e(),_c('div',{class:{
          'mt-2': _vm.isMobile && !_vm.imageOnRightSide && (_vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__),
        },domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_text__html_text__)}}),(_vm.hasCtaButtons)?_c('div',{staticClass:"style-1-base d-flex flex-wrap mt-4 mb-4",class:( _obj$1 = {
                  'style-2-base': _vm.invertColors
               }, _obj$1[_vm.classForButtonsPosition] = true, _obj$1 )},_vm._l((_vm.data.configuration.wb_buttons__array_cta_buttons__),function(button,index){return _c('default-button',{key:index,attrs:{"button":_vm.transformCtaButton(button),"additional-class":"ma-2"}},[_vm._v(" "+_vm._s(button.title)+" ")])}),1):_vm._e(),(!_vm.imageHidden && !_vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('flexible-carousel1',{class:( _obj$2 = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj$2[_vm.classForImagePosition] = true, _obj$2 ),attrs:{"data":_vm.data}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }